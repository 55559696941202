<template>
  <div class="collecTopic">
    <PullRefresh
      :loading="loading"
      :refreshing="refreshing"
      :finished="finished"
      @onLoad="onLoad"
      @onRefresh="onRefresh"
      :isHigehtMax="true"
      :isNoData="isNoData"
      :error="error"
    >
      <router-link tag="div" :to="`/tags/video?id=${item.id}`" class="topicItem" v-for="(item, index) in list" :key="index">
        <div class="selectBox" @click.stop="selectItem(item)" :class="{ activeSelectBox: isEdit }">
          <svg-icon class="select" iconClass="batchSelected" v-if="item.select" />
          <svg-icon class="select" iconClass="batchUnSelect" v-else />
        </div>
        <div class="main" :class="{ activeMain: isEdit }">
          <div class="content">
            <div class="">
              <ImgDecypt class="avatar" :src="item.coverImg" />
            </div>
            <div class="topicInfo">
              <div class="topicTitle">#{{ item.name }}</div>
              <div class="topicNum">话题总播放次数 {{ item.playCount | watchCount }}</div>
            </div>
            <div class="collected">
              <div class="text">{{ item.hasCollected ? '已收藏' : '收藏' }}</div>
            </div>
          </div>
        </div>
      </router-link>
    </PullRefresh>
  </div>
</template>

<script>
import { Toast } from 'vant';
import ImgDecypt from '@/components/ImgDecypt';
import PullRefresh from '@/components/PullRefresh';
import { queryCollecList } from '@/api/mine';
import { careCollectVideo } from '@/api/user';
import { getSessionItem } from '@/utils/longStorage';
export default {
  name: 'collecTopic',
  components: {
    ImgDecypt,
    PullRefresh,
  },
  data() {
    return {
      list: [], // 列表
      pageNumber: 1, // 页码
      pageSize: 10, // 条数
      loading: true, // loading
      refreshing: false, // 刷新状态
      finished: false, // 下拉状态
      error: false, // 是否显示错误信息
      isNoData: false, // 暂无数据
      isEdit: false,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 修改编辑状态
    changeEditSta(isEdit) {
      this.isEdit = isEdit;
    },
    // 选择批量删除的数据
    selectItem(work) {
      this.list.map((item) => (item.id == work.id ? this.$set(item, 'select', !item.select) : ''));
    },
    // 全选
    selectAll(isSelectAll) {
      this.list.map((item) => this.$set(item, 'select', isSelectAll));
    },
    // 批量删除
    batchDel() {
      const delArr = this.list.map((item) => (item.select ? item.id : undefined)).filter((item) => item);
      if (delArr.length == 0) return Toast('至少选择一项进行删除');
      this.delItem(delArr);
    },
    // 查询列表
    async getList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        type: 'tag', // 影视-movie 图文-img 视频-video 话题-tag
        uid: JSON.parse(getSessionItem('userInfo')).uid,
      };
      try {
        let res = await this.$Api(queryCollecList, req);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let list = res.data.list || [];
          if (type === 'refresh') {
            this.list = list;
          } else {
            this.list = this.list.concat(list);
          }
          // this.isNoData = this.comList.length > 0 ? false : true;
          if (this.list.length <= 0) {
            this.isNoData = true;
          }
          if (!res.data.hasNext) {
            this.finished = true;
          }
        } else {
          this.error = true;
        }
      } catch (error) {
        this.loading = false;
        this.error = true;
      }
    },
    // 上拉加载
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.getList();
    },
    // 下拉刷新
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.getList('refresh');
    },
    // 取消收藏
    async delItem(items) {
      let req = {
        type: 'tag',
        objIds: items,
        isCollect: false,
      };
      this.$store.commit('app/SET_LOADING', true);
      try {
        let res = await this.$Api(careCollectVideo, req);
        this.$store.commit('app/SET_LOADING', false);
        if (res.code === 200) {
          // this.list = this.list.filter((i) => {
          //   return i.id != info.id;
          // });
          // if (this.list.length <= 0) {
          //   this.isNoData = true;
          // }
          this.pageNumber = 1;
          this.loading = true;
          await this.getList('refresh');
          if (this.list.length == 0) {
            this.$emit('close');
          }
          Toast('删除成功');
          return;
        }
        Toast('操作失败');
      } catch (e) {
        this.$store.commit('app/SET_LOADING', false);
        Toast('操作失败');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.collecTopic {
  height: calc(100% - 92px);
  overflow-y: auto;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  .topicItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    .selectBox {
      padding: 0 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: translateX(-42px);
      transition: transform 0.3s;
      .select {
        height: 22px;
        width: 22px;
        border-radius: 50%;
      }
    }
    .main {
      background-color: #fff;
      width: 100%;
      padding: 8px 0px;
      transform: translateX(-42px);
      transition: transform 0.3s;
      .content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 350px;
        margin: 0 auto;
        padding: 0 12px;
        .avatar {
          width: 58px;
          height: 58px;
          border-radius: 6px;
          overflow: hidden;
          position: relative;
          z-index: 1;
        }

        .topicInfo {
          flex: 2;
          height: 58px;
          margin: 0 7px;
          padding-top: 5px;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .topicTitle {
            font-size: 16px;
          }

          .topicNum {
            font-size: 12px;
            color: rgb(102, 102, 102);
            margin-bottom: 6px;
          }
        }

        .collected {
          width: 70px;
          height: 27px;
          border-radius: 27px;
          line-height: 27px;
          text-align: center;
          border: 1px solid rgb(201, 201, 201);
          .text {
            font-size: 14px;
            color: rgb(201, 201, 201);
          }
        }
      }
    }
    .activeSelectBox {
      transform: translateX(0px);
      transition: transform 0.3s;
    }
    .activeMain {
      transform: translateX(0px);
      transition: transform 0.3s;
    }
  }
}
</style>
